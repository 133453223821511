import React from "react";
import Categories from "components/Categories";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { useAuth } from 'auth/useAuth';

function Home() {
    const auth = useAuth();
    const userProjects = auth.user ? <div>My Projects</div> : <br />;
    return (
        <div className="body">
            <div >
                <div>From TODO to DONE!</div>
                <div>Browse Projects to start!</div>
                {userProjects}
                <Categories />
            </div>
            <div>
            <Link to="/signup">
                <Button variant="primary">Join Us!</Button>
            </Link>
            </div>
        </div>
    )
}

export default Home; 