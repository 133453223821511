import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown';
import { useAuth } from 'auth/useAuth';

function LoginState() {
    const auth = useAuth();
    const logoutUser = () => {
        auth.signout();
    }
    if(auth.user) {
        console.log("auth: ", auth);
        return (
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {auth.user.profile.displayName}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => {logoutUser()}}>Logout</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        )
    } else {
        return (
            <Link to="/login">Login</Link>
        )
    }
}

export default LoginState; 