import React, { useState, useContext, useEffect } from "react";


import firebase from '../firebase'
import { IUser } from "interfaces/IUser";

type ContextProps = {
    user: IUser | null,
    emailSignin: (email: string, password: string) => any,
    googleSignin: () => any,
    emailSignup: (email: string, password: string) => any,
    googleSignup: () => any,
    signout: () => any
}

const AuthContext = React.createContext<ContextProps>({
    user: null,
    emailSignin: (email: string, password: string) => {},
    googleSignin: () => {},
    emailSignup: (email: string, password: string) => {},
    googleSignup: () => {},
    signout: () => {}
});

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: { children : React.ReactElement}) {
    const auth = useProvideAuth();
    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(AuthContext);
};

// Helper function to get the user object
const getUserObject = (user: any) => {
    return {
        id: user.uid,
        profile: {
            displayName: user.displayName,
            photoURL: user.photoURL,
            email: user.email
        } 
    }
}

// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState<IUser | null>(null);
    
    // Wrap any Firebase methods we want to use making sure ...
    // ... to save the user to state.
    const emailSignin = async (email: string, password: string) => {
      const res = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password);
        if (res.user) {
            const user = getUserObject(res.user);
            setUser(user);
        }
    };

    const googleSignin = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const res = await firebase
            .auth()
            .signInWithPopup(provider)
        if (res.user) {
            const user = getUserObject(res.user);
            setUser(user);
        }
      };
  
    const emailSignup = async (email: string, password: string) => {
      const res = await firebase
            .auth()
            .createUserWithEmailAndPassword(email, password);
        if (res.user) {
            const user = getUserObject(res.user);
            setUser(user);
        }
    };

    const googleSignup = async () => {
        const provider = new firebase.auth.GoogleAuthProvider();
        await firebase
            .auth()
            .setPersistence(firebase.auth.Auth.Persistence.LOCAL);
        const res = await firebase
            .auth()
            .signInWithPopup(provider);
        if (res.user) {
            const user = getUserObject(res.user);
            setUser(user);
        }
    }
  
    const signout = async () => {
      await firebase
            .auth()
            .signOut();
        setUser(null);
    };
  
    // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
      if (user) {
        console.log(user);
        setUser(getUserObject(user));
      } else {
        setUser(null);
      }
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);
  
  // Return the user object and auth methods
  return {
    user,
    emailSignin,
    googleSignin,
    emailSignup,
    googleSignup,
    signout,
  };

}