import React, { useState, useEffect } from "react";
import Jumbotron from 'react-bootstrap/Jumbotron';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
import { ICategory } from "interfaces/ICategory";

import firebase from '../firebase';

function useCategories() {
    const [categories, setCategories] = useState<ICategory[]>([]);
    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection('categories')
            .onSnapshot((snapshot) => {
                console.log("snapshot: ", snapshot.docs)
                const newCategories = snapshot.docs.map(doc => doc.data() as ICategory);
                setCategories(newCategories);
            })

            return () => unsubscribe();
    }, [])
    return categories;
}

function Categories() {
    const categories = useCategories();
    return (
      <Jumbotron>
        <h3>Categories</h3>
        <Container>
            <Row>
                {categories.map((category) => {
                    return <Col><CategoryCard category={category} /></Col>
                })}
            </Row>
        </Container>
      </Jumbotron>
    )
}

function CategoryCard({category} : {category: ICategory}) {
    return (
        <Link to={`/category/${category.id}`}>
            <Card style={{ width: '18rem', height: '20rem'}}>
                <Card.Img src={category.img} variant="top" alt={category.title}/>
                <Card.Body>
                    <Card.Title>{category.title}</Card.Title>
                    <Card.Text>{category.desc}</Card.Text>
                </Card.Body>
            </Card>
        </Link>
    )

}

export default Categories; 