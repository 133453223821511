import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { IProject } from "interfaces/IProject";
import { IUser } from "interfaces/IUser";
import firebase from '../firebase';
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Jumbotron from "react-bootstrap/Jumbotron";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { useAuth } from 'auth/useAuth';

function useTemplates(category: string | undefined) {
    const [templates, setTemplates] = useState<IProject[]>([]);
    useEffect(() => {
        const unsubscribe = firebase
            .firestore()
            .collection(`categories/${category}/templates`)
            .onSnapshot((snapshot) => {
                const newTemplates = snapshot.docs.map(doc => doc.data() as IProject);
                setTemplates(newTemplates);
            })

            return () => unsubscribe();
    }, [])
    return templates;
}


function Templates() {
    const { category } = useParams();
    const templates = useTemplates(category);
    const auth = useAuth();
    return (
    <div>
        <Jumbotron>
        <Link to={`/`}>
            <Button variant="primary">Back to Browse</Button>
        </Link>
        <br />
        <br />
        <h2>Templates in {category}</h2>
        <hr />
        <Container>
            <Row>
                {templates.map((template) => {
                    return <Col><Template template={template} user={auth.user}/></Col>
                })}
            </Row>
        </Container>
      </Jumbotron>
    </div>
    )
}

function addProject() {

}

function Template({template, user}: {template: IProject, user: IUser | null}) {
    const actionText = !user ? "Join us to get started!" : "Add project";
    return (
        <Card style={{ width: '18rem', height: '20rem'}}>
            <Card.Body>
                <Card.Title>{template.name}</Card.Title>
                <Card.Text>{template.desc}</Card.Text>
                <Link to={`/templates/${template.id}`}>
                    <Button variant="secondary">View Steps</Button>
                </Link>
                <hr />
                <Button variant="primary" onClick = {() => {addProject()}}>{actionText}</Button>
            </Card.Body>
        </Card>
    )
}

export default Templates; 