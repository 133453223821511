import {BrowserRouter as Router, Route, Switch, Link} from 'react-router-dom';
import React from 'react';
import './App.css';
import Home from 'pages/Home';
import Templates from 'components/Templates';
import Profile from 'pages/Profile';
import Browse from 'pages/Browse';
import Login from 'pages/Login';
import Signup from 'pages/Signup';
import LoginState from 'components/LoginState';

import { ProvideAuth } from 'auth/useAuth';
// function setUser(user: IUser | null): IUser | null{
//   return null;
// }

// type ContextProps = {
//   user: IUser | null,
//   setUser: Dispatch<SetStateAction<IUser | null>> | null
// }

// export const AuthContext = React.createContext<ContextProps>({user: null, setUser: null});

function App() {
  return (
    <ProvideAuth>
      <Router>
        <div className="container-fluid">
          <header className="topbar">
            <Link to="/" className="header"><h1>Asmi App</h1></Link>
            <LoginState />
          </header>
          <div>
            <Switch>
              <Route exact path="/" component={Home}></Route>
              <Route exact path="/category/:category" component={Templates}></Route>
              <Route exact path="/profile" component={Profile}></Route>
              <Route exact path="/browse" component={Browse}></Route>
              <Route exact path="/login" component={Login}></Route>
              <Route exact path="/signup" component={Signup}></Route>
            </Switch>
          </div>
        </div>
      </Router>
    </ProvideAuth>
  );
}

export default App;
