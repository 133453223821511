import React, { useState }from "react";
import { Link, Redirect } from "react-router-dom";
import { useAuth } from 'auth/useAuth';


function Signup() {
    const auth = useAuth();
    
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setErrors] = useState({code: "", message: ""});

    const handleLoginForm = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        auth.emailSignup(email, password);
    };

    if(auth.user) {
        return (
            <Redirect to='/' />
        )
    } else {
        return (
        <div>
            Signup
            <form onSubmit={e => handleLoginForm(e)}>
                <div>
                    <label>Email</label>
                    <input
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        name="email"
                        type="email"
                        placeholder="email"
                    />
                </div>
                <div>
                    <label>Password</label>
                    <input
                        onChange={e => setPassword(e.target.value)}
                        name="password"
                        value={password}
                        type="password"
                        placeholder="password"
                    />
                </div>
                <button>Signup</button>
            </form>
            <hr/>
            <button className="googleBtn" type="button" onClick={() => auth.googleSignup()}>
            <img
                src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                alt="logo"
            />
            Signup With Google
            </button>
            <p>{error.message}</p>
            <div>
                <h3>Already have an account?</h3>
                <Link to="/login">Login</Link>
            </div>
        </div>
        )
    }
}

export default Signup; 